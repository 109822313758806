.products-page{
    background: url("../images/product_image_new_3.png") no-repeat;
    background-size: contain;
}

.products-content-header{
    padding: 33px 0 50px;

    .text{
        font-family: $ruda;
        font-size: 0.875rem;
        font-weight: 400;
        @media #{$media-xl} {
            font-size: 1.125rem;
        }
    }
}

.products-container{
    position: relative;
    padding-top: 40px;
    h1{
        color: $white;
        font-size: 30px;
        font-weight: 700;
        padding-left: 20px;
        margin-bottom: 20px;
        span{
            position: relative;
            &:before{
                content: "";
                display: inline-block;
                background-color: $white;
                width: 4px;
                position: absolute;
                left: -20px;
                top: 0;
                height: 100%;
            }
        }
        @media #{$tablets} {
            font-size: 60px;
            margin-bottom: 100px;
            padding-left: 30px;
            &:before{
                min-height: 120px;
                top: -20px;
            }
        }
        @media #{$media-xl} {
            margin-bottom: 200px;
        }
    }
    @media #{$tablets} {
        padding-top: 100px;
    }
    @media #{$large-tablets} {
        margin-top: 0;
        padding-top: 200px;
    }
    @media #{$desktops} {
        padding-top: 310px;
    }
}

.products-navbar{
    padding-top: 4.286rem;
    ul{
        margin: 0;
        padding: 0;
        justify-content: space-between;
        li{
            list-style-type: none;
            padding-right: 1.429rem;
            padding-bottom: 29px;
            @media #{$media-xl} {
                padding-bottom: 0;
            }
        }
        @media #{$media-xl} {
            justify-content: flex-start;
        }
    }
    .main-button{
        background-color: transparent;
        border: 0;
        width: auto;
        font-size: 1.125rem;
        font-weight: 700;
        font-family: $cabin;
        &:hover, &.active{
            color: $main-green-color;
            span{
                position: relative;
                &:after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 5px;
                    background-color: $main-green-color;
                    border-radius: 5px;
                    position: relative;
                    bottom: -5px;
                }
            }
        }
        @media #{$media-xl} {
            width: 216px;
            background-color: $white;
            border: 1px solid $main-text-color;
            border-radius: 5px;
            &:hover, &.active{
                span{
                    &:after{
                        display: none;
                    }
                }
                background-color: $main-green-color;
                color: $main-text-color;
                border: 0;
            }
        }
    }
}

.products-list-holder{
    padding-top: 50px;
    .product-card{
        background-color: $light-silver;
        width: 146px;
        padding: 30px 0 0;
        margin-bottom: 10px;
        margin-left: 10px;
        .product-card-inner{
            max-width: 285px;
            margin: auto;

            .image-holder{
                width: 105px;
                height: 105px;
                display: block;
                margin: auto;
                @media #{$media-xl} {
                    height: auto;
                    width: 100%;
                }
            }
            .product-name{
                padding: 0 0.8125rem;
                h3{
                    color: $main-text-color;
                    font-size: 1.125rem;
                    font-weight: 700;
                    span{
                        font-size: 1.143rem;
                        font-family: $ruda;
                        font-weight: 400;
                        display: block;
                    }
                    @media #{$media-xl} {
                        font-size: 2.857rem;
                        span{
                            display: inline-block;
                        }
                    }
                }

                @media #{$media-xl} {
                    padding: 2.857rem 0 1.429rem;
                    border-bottom: 4px solid $main-green-color;
                }
            
            }
            .product-info{
                padding-top: 20px;
                h6{
                    font-weight: 700;
                    padding-bottom: 10px;
                }
                p{
                    font-family: $ruda;
                }
            }
            .main-button{
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                height: 48px;
                @media #{$media-xl} {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    width: 266px;
                    margin-top: 40px;
                }
            }
        }
        @media #{$media-xl} {
            margin: 0 0 20px 20px;
            padding: 50px 0;
            width: 570px;

            &:nth-of-type(odd){
                margin-left: 0;
            }
        }
    }
}