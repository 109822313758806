h2{
    @media #{$media-xl} {
        text-align: center;
        padding-left: 0;
        &:after{
            content: "";
            width: 65px;
            height: 4px;
            background-color: $main-text-color;
            display: block;
            margin: 50px auto 0;
        }
    }
}


.more-info{
    color: $main-text-color;
    font-size: 1.143rem;
    font-family: $ruda;
    padding-top: 20px;
    padding-bottom: 20px;
    @media #{$media-xl} {
        text-align: center;
        padding-bottom: 61px;
        padding-top: 31px;
    }
}

.verification-product-container{
    background-color: $main-green-color;
    padding: 45px 0;


    form{
        .form-control{
            width: 224px;
            height: 25px;
            border-radius: 3px;

            @media #{$media-xl} {
                width: 466px;
                border-radius: 3px;
                height: 62px;
            }
            @media #{$desktops} {
                width: 688px;
            }
        }
        button{
            width: 68px;
            height: 25px;
            margin-left: 20px;
            color: $main-text-color;
            background-color: $white;
            border: 0;
            border-radius: 3px;
            font-size: 0.625rem;
            @media #{$media-xl} {
                font-size: 1.286rem;
                height: 62px;
                width: 118px;
            }
        }
    }
    @media #{$media-xl} {
        padding: 97px 0;
    }
}
.additional-info-segments{
    padding-top: 50px;
    padding-bottom: 50px;

    &.first-row{
        @media #{$media-xl} {
            background: url("../images/parallax_background.png") no-repeat fixed center;
            background-size: cover;
        }
    }
    &.second-row{
        background-color: $light-silver;
    }

    h2{
        &:after{
            width: 100px;
            background-color: $main-green-color;
        }
    }

    @media #{$media-xl} {
        text-align: center;
        padding-top: 67px;
        padding-bottom: 127px;
        .main-button{
            margin: 0 auto;
        }
    }
}

.modal{
    .modal-dialog{
        margin: 10.75rem auto;
    }
    .modal-content{
        padding: 45px;
        background-color: $white;
        text-align: center;
        color: red;
        font-family: $roboto;
        font-weight: 700;
        max-width: 300px;
        margin: auto;

        p{
            font-size: 10px;
            @media #{$media-xl} {
                font-size: 18px;
            }
        }
        @media #{$media-xl} {
            max-width: 500px;
            padding: 91px 80px;
        }
    }
}