header{
    .container{
        padding-left: 0;
        padding-right: 0;
    }
    .navbar{
        &.open-mobile{
            border: 1px solid $main-text-color;
        }
    }
    .navbar-expand-lg .navbar-nav {
        .nav-item{
            .nav-link{
                color: $main-green-color;
                font-weight: 700;
                font-size: 14px;
            }
            @media #{$large-tablets} {
                padding-left: 83px;
                .nav-link{
                    color: $main-text-color;
                    font-size: 1rem;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                &.active, &:hover{
                    .nav-link{
                        background-color: #fbfbfb;
                        border-radius: 28px;
                    }
                }
            }

        }

    }
    .navbar-collapse{
        z-index: 999;
        position: absolute;
        left: 0;
        width: 100%;
        top: 58px;
        background-color: $main-text-color;
        text-align: center;
        padding: 36px 0;

        .nav-item{
            &:after{
                content: "";
                display: block;
                width: 40px;
                height: 1px;
                background-color: $main-green-color;
                margin: 12px auto;
            }
        }
        @media #{$large-tablets} {
            position: relative;
            background-color: transparent;
            padding: 0;
            top: 0;
            .nav-item{
                &:after{
                    display: none;
                }
            }
        }
    }
    @media #{$media-xl} {
        padding: 1.5rem 0;
        .container{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}