body{
  font-family: $cabin;

  .container{
    max-width: 1400px;
  }
  h2{
    font-size: 1.125rem;
    color: $main-text-color;
    font-weight: 700;
    @media #{$media-xl} {
      font-size: 2.857rem;
    }
  }

  .main-button{
    background-color: $main-green-color;
    width: 146px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $main-text-color;
    font-family: $ruda;
    font-weight: 400;
    font-size: 0.875rem;
    &:hover{
      background-color: $main-text-color;
      color: $white;
      text-decoration: none;
    }
    @media #{$media-xl} {
      font-size: 1.125rem;
      width: 216px;
      height: 56px;
    }
  }
  a{
    transition: $transition;
    &:hover{
      transition: $transition;
    }
  }
}
