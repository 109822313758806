.how-prt-work-page {
    .how-prt-work-page-holder{
       @media #{$large-tablets} {
           background: url("../images/work_parallax_background.png") no-repeat fixed center;
           background-size: cover;
           z-index: 0;
           position: relative;
       }
    }
    .info-container {
        position: relative;
        z-index: 2;
        padding: 15px;
        color: $main-text-color;
        font-family: $ruda;
        margin-top: -102px;

        h1 {
            color: $white;
            font-size: 24px;
            font-weight: 700;
            max-width: 924px;
            padding-left: 15px;
            padding-bottom: 50px;
            span{
                position: relative;
                &:before {
                    content: "";
                    display: inline-block;
                    background-color: $white;
                    width: 4px;
                    height: 100%;
                    position: absolute;
                    left: -10px;
                    top: 0
                }
            }
            @media #{$tablets} {
                font-size: 30px;
            }
            @media #{$media-xl} {
                font-size: 60px;
                margin-bottom: 53px;
            }
        }

        .info-box {
            color: $main-text-color;
            font-size: 16px;
            @media #{$tablets} {
                padding: 15px;
                background-color: $white;
                box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.3);
            }
        }

        .other-info-container {
            padding-top: 30px;
            display: block;
            width: 100%;
            border-bottom: 1px solid $main-text-color;
            margin-bottom: 50px;
            h3 {
                padding-bottom: 51px;
                font-size: 1.125rem;
                @media #{$media-xl} {
                    font-size: 1.875rem;
                }
            }
            p {
                font-size: 0.75rem;
                &:before {
                    content: '';
                    background-color: $main-text-color;
                    display: inline-block;
                    width: 98px;
                    height: 1px;
                    margin-right: 20px;
                    top: -3px;
                    position: relative;
                }
                @media #{$media-xl} {
                    font-size: 1rem;
                }
            }

            &:last-child {
                border-bottom: 0;
            }

            .text-content{
                padding-bottom: 30px;
                font-size: 0.875rem;
            }

        }


        @media #{$tablets} {
            margin-top: -150px;
        }

        @media #{$media-xl} {
            width: 882px;
            margin-top: -250px;
            .info-box {
                border-radius: 5px;
                padding: 80px 98px 80px 117px;
            }
            .other-info-container {
                padding: 80px 98px 80px 117px;

                .text-content{
                    padding-bottom: 0;
                }
            }
        }

        @media #{$desktops} {
            margin-top: -450px;
        }
    }
}