.description-content{
    background-color: $light-silver;
    padding: 15px;
    margin-top: 27px;
    margin-bottom: 70px;
    h3{
        font-size: 18px;
        font-weight: 700;
        color: $main-text-color;
    }
    h4{
        color: $main-text-color;
        font-size: 14px;
        font-weight: 700;
    }

    .main-button{
        background-color: transparent;
        border: 1px solid $main-text-color;
        font-size: 18px;
        width: 67px;
        @media #{$media-xl} {
            width: 216px;
        }
    }

    .info-container{
        padding-top: 40px;
        padding-left: 23px;
        &.card-columns{
            column-count: 1;
            .info-box{
                display: inline-block;
                width: 100%;
                margin-bottom: 40px;
                color: $main-text-color;
                font-family: $ruda;
                font-size: 16px;
                @media #{$media-xl} {
                    width: 453px;
                }
            }
            @media #{$media-xl} {
                column-count: 2;
            }
        }

        @media #{$media-xl} {
            padding-right: 150px;
        }

    }

    @media #{$media-xl} {
        padding: 56px 100px;

        h3{
            font-size: 40px;
        }
        h4{
            font-size: 30px;
            padding-left: 23px;
        }
    }
}