//COLOR SCHEME
$white: #ffffff;
$light-silver: #F9F9F9;
$main-text-color: #073B4C;
$main-green-color: #06D6A0;

// Font families

$ruda: 'Ruda', sans-serif;
$cabin: 'Cabin', sans-serif;
$roboto: 'Roboto', sans-serif;

// Transition
$transition : ease 0.2s;


//Media Screens
$tablets: "(min-width: 768px)";
$large-tablets: "(min-width: 1024px)";
$media-xl: "(min-width: 1280px)";
$desktops: "(min-width: 1600px)";
$large-screens: "(min-width: 1920px)";
