.contacts-page {
    background: url("../images/ContactImage.png") no-repeat;
    background-size: contain;

    .top-image-content {
        position: relative;

    }

    .contacts-container {
        z-index: 2;
        position: relative;
        padding-top: 30px;
        margin-bottom: 60px;

        h1 {
            color: $white;
            font-size: 30px;
            font-weight: 700;
            padding-left: 20px;
            margin-bottom: 30px;
            span{
                position: relative;
                &:before {
                    content: "";
                    display: inline-block;
                    background-color: $white;
                    width: 4px;
                    position: absolute;
                    left: -20px;
                    top: 0;
                    height: 100%;
                }
            }
            @media #{$media-xl} {
                font-size: 60px;
                margin-bottom: 100px;
                padding-left: 30px;
                &:before {
                    min-height: 120px;
                    top: -20px;
                }
            }
        }

        @media #{$tablets} {
            padding-top: 80px;
        }
        @media #{$media-xl} {
            padding-top: 190px;
            margin-bottom: 172px;
        }
    }

    .contact-form {
        padding: 35px 68px 48px 68px;
        color: $white;
        font-family: $ruda;

        background-color: $main-text-color;
        border-radius: 5px;

        p {
            max-width: 438px;
            padding-bottom: 30px;
        }

        input {
            height: 26px;
            @media #{$media-xl} {
                height: 40px;
            }
        }
        .form-control {
            margin-bottom: 20px;
        }
        textarea {
            min-height: 67px;

            @media #{$media-xl} {
                min-height: 203px;
            }
        }
        button {
            display: block;
            width: 98px;
            height: 28px;
            background-color: $white;
            border: 0;
            border-radius: 5px;
            margin: 0 0 0 auto;
        }
        @media #{$media-xl} {

            width: 924px;
            border-radius: 5px;
            padding: 73px 118px 103px;
            z-index: 2;
            position: relative;

            button {
                height: 41px;
            }

            p {
                padding-bottom: 60px;
            }
        }

    }

}