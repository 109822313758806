footer{
    background-color: $main-green-color;
    padding: 35px 0;
    text-align: center;

    .navbar{
        justify-content: center;
    }

    .nav-item{
        padding-top: 25px;
        &:first-child{
            padding-left: 0;
        }
        .nav-link{
            font-size: 0.875rem;
            color: $main-text-color;
            font-weight: 700;
            @media #{$desktops} {
                font-size: 1rem;
            }
        }
        &.copyrights{
            opacity: 0.5;
            color: $main-text-color;
            font-family: $roboto;
            font-size:  0.75rem;
            @media #{$media-xl} {
                font-size: 1.286rem;
            }
        }
    }

    @media #{$media-xl} {
        text-align: left;
        padding: 79px 0;

        .navbar{
            justify-content: space-between;
        }

        .brand-logo{
            padding-right: 115px;
        }
        .nav-item{
            padding-top: 0;
            padding-left: 86px;
        }
    }
    
}